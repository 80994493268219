
import { Component, Vue } from "vue-property-decorator";
// import { Field } from 'vant';
// import { accountLoginRequest } from "@/service/api";
// import { Toast } from "vant";
import { closeWebView } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "Perfectfail",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class Perfectfail extends Vue {
  private async handleBuy() {
    closeWebView();
  }
  private orderNo: any = localStorage.getItem("stic-orderId");
  mounted() {
    Toast.fail(this.$t("ruzhangshibai"));
  }
}
